<div class="flex mb-3 gap-2 justify-content-end header-buttons">
  <p-button
    (click)="activeIndex = 0"
    rounded="true"
    [pTooltip]="'calendarComponent.appointment.newAppointment.sections.information'
          | translate"
    tooltipPosition="top"
    styleClass="w-2rem h-2rem p-0 pi pi-cog"
    [outlined]="activeIndex !== 0" />
  <p-button
    (click)="activeIndex = 1"
    rounded="true"
    [pTooltip]="'calendarComponent.appointment.newAppointment.sections.appointment'
          | translate"
    tooltipPosition="top"
    styleClass="w-2rem h-2rem p-0 pi pi-calendar"
    [outlined]="activeIndex !== 1" />
  <p-button
    *ngIf="appointment.technician"
    (click)="activeIndex = 2"
    rounded="true"
    [pTooltip]="'calendarComponent.appointment.newAppointment.sections.ticket'
          | translate"
    tooltipPosition="top"
    styleClass="w-2rem h-2rem p-0 pi pi-ticket"
    [outlined]="activeIndex !== 2" />
  <p-button
    (click)="activeIndex = 3"
    rounded="true"
    [pTooltip]="'calendarComponent.appointment.newAppointment.sections.other'
          | translate"
    tooltipPosition="top"
    styleClass="w-2rem h-2rem p-0 pi pi-comment"
    [outlined]="activeIndex !== 3" />
</div>
<p-accordion
  (activeIndexChange)="activeIndexChange($event)"
  [activeIndex]="activeIndex"
  class="w-full"
  expandIcon="pi pi-plus text-primary"
  collapseIcon="pi pi-minus text-primary">
  <!-- General Information -->
  <p-accordionTab>
    <!-- Accordion-tab header - General information -->
    <ng-template pTemplate="header">
      <span class="flex align-items-center gap-2 w-full">
        <span class="pi pi-cog"></span>
        <span class="font-bold white-space-nowrap">
          {{'calendarComponent.appointment.newAppointment.sections.information'
          | translate}}
        </span>
        <p-badge
          *ngIf="getInformationToDos() !== '0'"
          [value]="getInformationToDos()"
          [pTooltip]="tooltipInformationTemplate"
          class="ml-auto mr-2" />
      </span>
    </ng-template>

    <div class="grid p-fluid formgrid">
      <div class="col-12 field">
        <label for="title" class="text-900 font-semibold"
          >{{'calendarComponent.appointment.newAppointment.fields.subject' |
          translate}}</label
        >
        <input
          id="title"
          type="text"
          pInputText
          [ngClass]="{ 'ng-invalid ng-dirty': !appointment.title }"
          [disabled]="view === 'display'"
          [placeholder]="'general.form.enterSubject'| translate:
      {subject: ('calendarComponent.appointment.newAppointment.fields.subject' | translate)}"
          [(ngModel)]="appointment.title" />
      </div>
      <div class="col-12 field">
        <label for="description" class="text-900 font-semibold"
          >{{'calendarComponent.appointment.newAppointment.fields.description' |
          translate}}</label
        >
        <textarea
          id="description"
          type="text"
          pInputTextarea
          [rows]="5"
          [disabled]="view === 'display'"
          [placeholder]="'general.form.enterSubject'| translate:
      {subject: ('calendarComponent.appointment.newAppointment.fields.description' | translate)}"
          [(ngModel)]="appointment.description"
          style="resize: none"></textarea>
      </div>
    </div>
  </p-accordionTab>
  <!-- Appointment -->
  <p-accordionTab>
    <!-- Accordion-tab header - Appointment -->
    <ng-template pTemplate="header">
      <span class="flex align-items-center gap-2 w-full">
        <span class="pi pi-calendar"></span>
        <span class="font-bold white-space-nowrap">
          {{'calendarComponent.appointment.newAppointment.sections.appointment'
          | translate}}
        </span>
        <p-badge
          *ngIf="getAppointmentToDos() !== '0'"
          [value]="getAppointmentToDos()"
          [pTooltip]="tooltipAppointmentTemplate"
          class="ml-auto mr-2" />
      </span>
    </ng-template>

    <div class="flex gap-4">
      <div class="w-2">
        <label for="start" class="text-900 font-semibold"
          >{{'calendarComponent.appointment.newAppointment.fields.start' |
          translate}}</label
        >
      </div>
      <div>
        <p-calendar
          [appendTo]="dialogReference"
          dateFormat="dd.mm.yy"
          [firstDayOfWeek]="1"
          [iconDisplay]="'input'"
          [showIcon]="true"
          [required]="true"
          inputId="startDate"
          [disabled]="view === 'display'"
          [hideOnDateTimeSelect]="false"
          [(ngModel)]="start.date"
          (ngModelChange)="onDateChange()"></p-calendar>
      </div>
      <div>
        <p-calendar
          [appendTo]="dialogReference"
          dateFormat="dd.mm.yy"
          [firstDayOfWeek]="1"
          [iconDisplay]="'input'"
          [showIcon]="true"
          stepMinute="15"
          [required]="true"
          inputId="startTime"
          [timeOnly]="true"
          [disabled]="view === 'display'"
          [hideOnDateTimeSelect]="false"
          [(ngModel)]="start.time"
          (ngModelChange)="onDateChange()"
          ><ng-template pTemplate="inputicon" let-clickCallBack="clickCallBack">
            <i
              class="pi pi-clock pointer-events-none"
              (click)="clickCallBack($event)"></i> </ng-template
        ></p-calendar>
      </div>
      <div class="flex align-self-center gap-2">
        <p-inputSwitch
          [required]="true"
          inputId="allDay"
          [disabled]="view === 'display'"
          (ngModelChange)="onAllDayChange()"
          [(ngModel)]="appointment.allDay"></p-inputSwitch>
        <label for="start" class="text-900 font-semibold"
          >{{'calendarComponent.appointment.newAppointment.fields.allDay' |
          translate}}</label
        >
      </div>
    </div>
    <div class="flex gap-4 mt-2">
      <div class="w-2">
        <label for="end" class="text-900 font-semibold"
          >{{'calendarComponent.appointment.newAppointment.fields.end' |
          translate}}</label
        >
      </div>
      <div>
        <p-calendar
          [appendTo]="dialogReference"
          dateFormat="dd.mm.yy"
          [firstDayOfWeek]="1"
          [iconDisplay]="'input'"
          [showIcon]="true"
          [required]="true"
          inputId="endDate"
          [disabled]="view === 'display'"
          [hideOnDateTimeSelect]="false"
          [(ngModel)]="end.date"
          (ngModelChange)="onDateChange()"></p-calendar>
      </div>
      <div>
        <p-calendar
          [appendTo]="dialogReference"
          dateFormat="dd.mm.yy"
          [firstDayOfWeek]="1"
          [iconDisplay]="'input'"
          [showIcon]="true"
          stepMinute="15"
          [required]="true"
          inputId="endTime"
          [timeOnly]="true"
          [disabled]="view === 'display'"
          [hideOnDateTimeSelect]="false"
          [(ngModel)]="end.time"
          (ngModelChange)="onDateChange()">
          <ng-template pTemplate="inputicon" let-clickCallBack="clickCallBack">
            <i
              class="pi pi-clock pointer-events-none"
              (click)="clickCallBack($event)"></i> </ng-template
        ></p-calendar>
      </div>
      <div class="flex align-self-center gap-2">{{duration}}</div>
    </div>

    <div class="grid p-fluid formgrid mt-2">
      <div class="col-12 md:col-6 field">
        <label for="users" class="text-900 font-semibold"
          >{{'calendarComponent.appointment.newAppointment.fields.technician' |
          translate}}</label
        >
        <p-dropdown
          *ngIf="view !== 'display'"
          inputId="users"
          dataKey="id"
          [appendTo]="dialogReference"
          [options]="users"
          [disabled]="view === 'display'"
          optionLabel="optionLabel"
          [filter]="true"
          filterBy="optionLabel"
          [filterPlaceholder]="'general.form.filter'| translate"
          [(ngModel)]="appointment.technician"
          (ngModelChange)="technicianSelected($event)"
          [placeholder]="'general.form.chooseSubject'| translate:
      {subject: ('calendarComponent.appointment.newAppointment.fields.technician' | translate)}"
          optionLabel="name">
          <ng-template pTemplate="selectedItem">
            <div
              *ngIf="appointment.technician"
              class="flex align-items-center gap-2">
              <app-user-avatar [user]="appointment.technician" size="normal" />
              <span
                >{{appointment.technician.firstname}}
                {{appointment.technician.lastname}}</span
              >
            </div>
          </ng-template>
          <ng-template let-user pTemplate="item">
            <div class="flex align-items-center gap-2">
              <app-user-avatar [user]="user" size="normal" />
              <span>{{user.firstname}} {{user.lastname}}</span>
            </div>
          </ng-template>
        </p-dropdown>
        <div *ngIf="view === 'display' && appointment.technician">
          <div class="flex align-items-center gap-2">
            <app-user-avatar [user]="appointment.technician" size="normal" />
            <span
              >{{appointment.technician.firstname}}
              {{appointment.technician.lastname}}</span
            >
          </div>
        </div>
      </div>

      <div
        class="col-12 md:col-6 field"
        *ngIf="!appointment.id || view !== 'display' || (appointment.id && appointment.otherTechnicians && appointment.otherTechnicians.length > 0)">
        <label for="users" class="text-900 font-semibold"
          >{{'calendarComponent.appointment.newAppointment.fields.otherTechnicians'
          | translate}}</label
        >
        <p-multiSelect
          *ngIf="view !== 'display'"
          inputId="otherTechnicians"
          dataKey="id"
          [appendTo]="dialogReference"
          [(ngModel)]="selectedOtherTechnicians"
          optionLabel="optionLabel"
          [options]="users"
          [filter]="true"
          [filterPlaceHolder]="'general.form.filter'| translate"
          (onChange)="mapOtherTechnicians($event.value)">
          <ng-template let-option pTemplate="item">
            <div class="inline-block vertical-align-middle">
              <div class="flex gap-3 align-items-center">
                <div
                  class="inline-block vertical-align-middle flex gap-3 align-items-center white-space-nowrap">
                  <app-user-avatar [user]="option" size="normal" />
                  <span>{{ option.firstname }} {{ option.lastname }}</span>
                </div>
              </div>
            </div>
          </ng-template>

          <ng-template let-options pTemplate="selectedItems">
            <div class="inline-block vertical-align-middle" *ngIf="options">
              <div class="inline-flex align-items-center gap-2 px-1">
                <ng-container
                  *ngIf="options.length === 1; else multipleSelected">
                  <div
                    class="inline-block vertical-align-middle flex gap-3 align-items-center white-space-nowrap">
                    <app-user-avatar [user]="options[0]" size="normal" />
                    <span
                      >{{ options[0].firstname }} {{ options[0].lastname
                      }}</span
                    >
                  </div>
                </ng-container>
                <ng-template #multipleSelected>
                  <div
                    class="inline-block vertical-align-middle flex gap-3 align-items-center white-space-nowrap"
                    *ngFor="let option of options; index as i">
                    <app-user-avatar [user]="option" size="normal" />
                  </div>
                </ng-template>
              </div>
            </div>
          </ng-template>
        </p-multiSelect>
        <div *ngIf="view === 'display' && appointment.otherTechnicians">
          <div class="flex align-items-center gap-2">
            @for (technician of appointment.otherTechnicians; track $index) {
            <app-user-avatar [user]="technician" size="normal" />
            @if (appointment.otherTechnicians.length >= 1 &&
            appointment.otherTechnicians.length <= 2) {
            <span>{{technician.firstname}} {{technician.lastname}}</span>
            } }
          </div>
        </div>
      </div>
      <div>
        <div class="flex align-items-center gap-2">
          <p-inputSwitch
            [required]="true"
            inputId="reserved"
            [disabled]="view === 'display'"
            (ngModelChange)="onReservedChange()"
            [(ngModel)]="appointment.reserved"></p-inputSwitch>
          <label for="start" class="text-900"
            >{{'calendarComponent.appointment.newAppointment.fields.reserved' |
            translate}}</label
          >
        </div>
      </div>
    </div>
  </p-accordionTab>
  <!-- Ticket -->
  <p-accordionTab *ngIf="appointment.technician">
    <!-- Accordion-tab header - Ticket -->
    <ng-template pTemplate="header">
      <span class="flex align-items-center gap-2 w-full">
        <span class="pi pi-ticket"></span>
        <span class="font-bold white-space-nowrap">
          {{'calendarComponent.appointment.newAppointment.sections.ticket' |
          translate}}
        </span>
        <p-badge
          *ngIf="getTicketToDos() !== '0'"
          [value]="getTicketToDos()"
          [pTooltip]="tooltipTicketTemplate"
          class="ml-auto mr-2" />
      </span>
    </ng-template>

    <div class="grid p-fluid formgrid">
      <div
        [ngClass]="appointment.ticket && appointment.ticket.customer ? 'col-6' : 'col-12'"
        class="field">
        <label for="tickets" class="text-900 font-semibold"
          >{{'calendarComponent.appointment.newAppointment.fields.ticket' |
          translate}}</label
        >
        <span
          *ngIf="appointment.ticket?.description || appointment.ticket?.requestReason"
          (click)="tooltipTicketInfoTemplate.toggle($event)"
          class="pi pi-info-circle text-primary ml-2 cursor-pointer"></span>
        <p-overlayPanel #tooltipTicketInfoTemplate>
          <div class="mb-1">
            <div class="mb-1">
              {{'ticketComponent.attributes.description' | translate}}
            </div>
            <textarea
              *ngIf="appointment.ticket?.description"
              id="description"
              type="text"
              pInputTextarea
              [rows]="5"
              [disabled]="true"
              [ngModel]="appointment.ticket?.description"
              style="resize: none; width: 40rem"></textarea>
          </div>
          <div>
            <div class="mb-1">
              {{'ticketComponent.attributes.requestReason' | translate}}
            </div>
            <textarea
              *ngIf="appointment.ticket?.requestReason"
              id="requestReason"
              type="text"
              pInputTextarea
              [rows]="5"
              [disabled]="true"
              [ngModel]="appointment.ticket?.requestReason"
              style="resize: none; width: 40rem"></textarea>
          </div>
        </p-overlayPanel>
        <p-dropdown
          *ngIf="!appointment.id || appointment.id && !appointment.ticket"
          inputId="tickets"
          [appendTo]="dialogReference"
          [options]="tickets"
          dataKey="id"
          [filter]="true"
          [disabled]="view === 'display'"
          (onFilter)="customFilter($event)"
          [(ngModel)]="appointment.ticket"
          (ngModelChange)="ticketSelected($event)"
          [placeholder]="'general.form.chooseSubject'| translate:
          {subject: ('calendarComponent.appointment.newAppointment.fields.ticket' | translate)}"
          optionLabel="name">
          <ng-template pTemplate="filter" let-options="options">
            <div class="flex gap-1">
              <div class="p-inputgroup" (click)="$event.stopPropagation()">
                <span class="p-inputgroup-addon"
                  ><i class="pi pi-search"></i
                ></span>
                <input
                  type="text"
                  pInputText
                  placeholder="Filter"
                  [value]="ticketFilterValue"
                  (input)="ticketFilter($event)" />
              </div>
              <span
                class="pi pi-times cursor-pointer align-content-center text-red-700"
                (click)="resetFunction()"></span>
            </div>
          </ng-template>
          <ng-template pTemplate="selectedItem">
            <div *ngIf="appointment.ticket" class="flex align-items-center">
              <div>
                {{ appointment.ticket.ticketNumber }} | {{
                appointment.ticket.subject}}
              </div>
            </div>
          </ng-template>
          <ng-template let-ticket pTemplate="item">
            <div class="flex align-items-center">
              <div>{{ ticket.ticketNumber }} | {{ ticket.subject}}</div>
            </div>
          </ng-template>
        </p-dropdown>
        <div *ngIf="appointment.id && appointment.ticket">
          <div>
            <span class="link" (click)="navigateToTicket(appointment.ticket.id)"
              >{{ appointment.ticket.ticketNumber }} |
              {{appointment.ticket.subject}}</span
            >
          </div>
        </div>
      </div>

      <div
        *ngIf="appointment.ticket && appointment.ticket.customer"
        class="col-6 field">
        <label class="text-900 font-semibold"
          >{{'calendarComponent.appointment.newAppointment.fields.customer' |
          translate}}</label
        >
        <div *ngIf="!appointment.id">
          <input
            id="customer"
            type="text"
            pInputText
            [disabled]="true"
            [ngModel]="appointment.ticket.customer.name" />
        </div>
        <div *ngIf="appointment.id">
          <span
            class="link"
            (click)="navigateToCustomer(appointment.ticket.customer.id)"
            >{{ appointment.ticket.customer.customerNumber }} |
            {{appointment.ticket.customer.name}}</span
          >
        </div>
      </div>

      <div class="col-12 field" *ngIf="appointment.ticket">
        <label for="description" class="text-900 font-semibold"
          >{{'calendarComponent.appointment.newAppointment.fields.forms' |
          translate}}</label
        >
        <app-ticket-form
          [isInAppointment]="true"
          [showEditAction]="view !== 'display'"
          [showCreateAction]="view !== 'display'"
          [isSelectable]="view !== 'display'"
          [selectionDisabled]="view === 'display'"
          [showGlobalSearch]="view !== 'display'"
          [showSelectedFormsOnly]="view === 'display'"
          [ticket]="appointment.ticket"
          [showTechnicianColumn]="true"
          [showCreatedAtColumn]="false"
          [showPdfAction]="false"
          [showStatusColumn]="false"
          [showCompletedAtColumn]="false"
          [showDeleteAction]="false"
          [showDeadlineInSidebar]="true"
          [showTechnicianInSidebar]="true"
          [currentAppointmentId]="appointment.id || null"
          [smallTable]="true"
          [users]="users"
          [selectedForms]="selectedForms"
          (handleFormEdited)="handleFormEdited($event)"
          (handleSelectedFormsChange)="handleSelectedFormsChange($event)"
          [deadlineEditable]="true"></app-ticket-form>
      </div>
    </div>
  </p-accordionTab>
  <!-- Other -->
  <p-accordionTab>
    <!-- Accordion-tab header - Other -->
    <ng-template pTemplate="header">
      <span class="flex align-items-center gap-2 w-full">
        <span class="pi pi-comment"></span>
        <span class="font-bold white-space-nowrap">
          {{'calendarComponent.appointment.newAppointment.sections.other' |
          translate}}
        </span>
      </span>
    </ng-template>
    <div class="grid p-fluid formgrid">
      <div class="col-12 field">
        <label for="description" class="text-900 font-semibold"
          >{{'calendarComponent.appointment.newAppointment.fields.notes' |
          translate}}</label
        >
        <textarea
          id="notes"
          type="text"
          pInputTextarea
          [rows]="5"
          [disabled]="view === 'display'"
          [placeholder]="'general.form.enterSubject'| translate: {subject: ('calendarComponent.appointment.newAppointment.fields.notes' | translate)}"
          [(ngModel)]="appointment.notes"
          (ngModelChange)="notesChanged($event)"
          style="resize: none"></textarea>
      </div>
    </div>
  </p-accordionTab>
</p-accordion>

<!-- General information tooltip template -->
<ng-template #tooltipInformationTemplate>
  <div>
    @switch (getInformationToDos()) { @case ('1') {
    <span
      >{{
      'calendarComponent.appointment.newAppointment.sections.tooltip.single'|
      translate }}</span
    >
    } @case ('2') {
    <span
      >{{
      'calendarComponent.appointment.newAppointment.sections.tooltip.multiple'|
      translate: {count: (getInformationToDos())} }}
    </span>
    } @default { } }
  </div>

  <ul>
    @if (!appointment.title) {
    <li>
      {{'calendarComponent.appointment.newAppointment.fields.subject' |
      translate}}
    </li>
    } @if (!appointment.description) {
    <li>
      {{'calendarComponent.appointment.newAppointment.fields.description' |
      translate}}
    </li>
    }
  </ul>
</ng-template>
<!-- Appointment tooltip template -->
<ng-template #tooltipAppointmentTemplate>
  <div>
    @switch (getAppointmentToDos()) { @case ('1') {
    <span
      >{{
      'calendarComponent.appointment.newAppointment.sections.tooltip.single'|
      translate }}</span
    >
    } @case ('2') {
    <span
      >{{
      'calendarComponent.appointment.newAppointment.sections.tooltip.multiple'|
      translate: {count: (getAppointmentToDos())} }}
    </span>
    } @case ('3') {
    <span
      >{{
      'calendarComponent.appointment.newAppointment.sections.tooltip.multiple'|
      translate: {count: (getAppointmentToDos())} }}
    </span>
    } @default { } }
  </div>

  <ul>
    @if (!appointment.start) {
    <li>
      {{'calendarComponent.appointment.newAppointment.fields.start' |
      translate}}
    </li>
    } @if (!appointment.end) {
    <li>
      {{'calendarComponent.appointment.newAppointment.fields.end' | translate}}
    </li>
    } @if (!appointment.technician) {
    <li>
      {{'calendarComponent.appointment.newAppointment.fields.technician' |
      translate}}
    </li>
    }
  </ul>
</ng-template>
<!-- Ticket tooltip template -->
<ng-template #tooltipTicketTemplate>
  <div>
    @switch (getTicketToDos()) { @case ('1') {
    <span
      >{{
      'calendarComponent.appointment.newAppointment.sections.tooltip.single'|
      translate }}</span
    >
    } @case ('2') {
    <span
      >{{
      'calendarComponent.appointment.newAppointment.sections.tooltip.multiple'|
      translate: {count: (getTicketToDos())} }}
    </span>
    } @default { } }
  </div>

  <ul>
    @if (getTicketToDos() !== '0') {
    <li>
      {{'calendarComponent.appointment.newAppointment.fields.forms' |
      translate}}
    </li>
    }
  </ul>
</ng-template>
