import { Component, Input, OnInit, Optional } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';
import { Ticket } from 'src/app/models/Ticket';
import { Router } from '@angular/router';

@Component({
  selector: 'app-ticket-appointment',
  templateUrl: './ticket-appointment.component.html',
  styleUrls: ['./ticket-appointment.component.scss']
})
export class TicketAppointmentComponent implements OnInit {
  @Input() formGroupName?: string;

  @Input() ticket!: Ticket;

  form!: FormGroup;

  constructor(
    @Optional() private rootFormGroup: FormGroupDirective,
    private readonly router: Router
  ) {}

  ngOnInit(): void {
    if (this.formGroupName) {
      this.form = this.rootFormGroup.control.get(
        this.formGroupName
      ) as FormGroup;
    }
  }

  navigateToCalendar(): void {
    // ToDo: Replace this with showing the "MyDay-View" as soon as its available
    this.router.navigate(['/calendar']);
  }

  /**
   * Checks if the appointment is in the past based on end date.
   *
   * @param {string} endDate - The end date of the appointment.
   * @returns {boolean} - True if the appointment is in the past, false otherwise.
   */
  isPastAppointment(endDate: string): boolean {
    const now = new Date();
    const appointmentEndDate = new Date(endDate);

    return appointmentEndDate < now;
  }
}
