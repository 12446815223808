<div [ngClass]="standaloneCard ? 'card' : ''">
  <p-table
    *ngIf="!isLoading"
    #tableElement
    dataKey="id"
    [rows]="tableConfig.rows"
    [scrollHeight]="tableConfig.scrollHeight"
    [filterDelay]="1"
    [value]="virtualTickets"
    [paginator]="true"
    [showCurrentPageReport]="true"
    [currentPageReportTemplate]="'table.pageReportTemplate' | translate"
    [globalFilterFields]="['subject', 'ticketNumber', showCustomerColumn ? 'virtual.customer':'','virtual.virtualSearch.heatingEngineerSearch', 'virtual.virtualSearch.appointmentsAndTechnicians', 'virtual.ticketStatus', 'virtual.ticketCategory', 'virtual.editor', 'virtual.createdBy', 'createdAt', 'virtual.isReoccurringTicket', 'executionDate']"
    [rowsPerPageOptions]="tableConfig.rowsPerPageOptions"
    [scrollable]="true"
    stateStorage="local"
    [resizableColumns]="true"
    columnResizeMode="expand"
    stateKey="ticket-table-memory">
    <ng-template pTemplate="caption">
      <div class="flex relative align-items-center justify-content-between">
        <div>
          <span class="p-input-icon-left ml-auto">
            <i class="pi pi-search"></i>
            <input
              pInputText
              [value]="globalSearchValue"
              type="text"
              (input)="tableElement.filterGlobal($any($event.target).value, 'contains')"
              [placeholder]="'general.globalSearchPlaceholder' | translate" />
          </span>
        </div>
        <div *ngIf="tableConfig.showTableHeader" class="title-container">
          <h4>{{ "ticketComponent.table.title" | translate}}</h4>
        </div>
        <div *ngIf="showCreateButton">
          <button
            pButton
            pRipple
            type="button"
            icon="pi pi-plus-circle"
            [label]="'ticketComponent.newTicket' | translate"
            class="flex-auto p-button-outlined"
            (click)="createTicket()"></button>
        </div>
      </div>
    </ng-template>

    <ng-template pTemplate="header">
      <tr>
        <th
          pResizableColumn
          pSortableColumn="virtual.ticketCategoryType"
          class="white-space-nowrap">
          {{ 'ticketComponent.table.columns.ticketCategoryType.title' |
          translate }}
          <p-sortIcon field="virtual.ticketCategoryType" />
        </th>
        <th
          pResizableColumn
          pSortableColumn="virtual.virtualSearch.appointmentsAndTechniciansSearch"
          class="white-space-nowrap">
          {{ 'ticketComponent.table.columns.appointmentsAndTechnicians.title' |
          translate }}
          <p-sortIcon
            field="virtual.virtualSearch.appointmentsAndTechniciansSearch" />
        </th>
        <th
          pResizableColumn
          pSortableColumn="ticketNumber"
          class="white-space-nowrap">
          {{ 'ticketComponent.table.columns.ticketNumber.title' | translate }}
          <p-sortIcon field="ticketNumber" />
        </th>
        <th
          pResizableColumn
          pSortableColumn="subject"
          class="white-space-nowrap">
          {{ 'ticketComponent.table.columns.subject.title' | translate }}
          <p-sortIcon field="subject" />
        </th>
        <th
          pResizableColumn
          pSortableColumn="virtual.virtualSearch.heatingEngineerSearch"
          class="white-space-nowrap">
          {{ 'ticketComponent.table.columns.heatingEngineers.title' | translate
          }}
          <p-sortIcon field="virtual.virtualSearch.heatingEngineerSearch" />
        </th>
        <th
          *ngIf="showCustomerColumn"
          pResizableColumn
          pSortableColumn="virtual.customer"
          class="white-space-nowrap">
          {{ 'ticketComponent.table.columns.customer.title' | translate }}
          <p-sortIcon field="virtual.customer" />
        </th>
        <th
          *ngIf="showCustomerColumn"
          pResizableColumn
          pSortableColumn="virtual.facilityAddress"
          class="white-space-nowrap">
          {{ 'ticketComponent.table.columns.facilityAddressType.title' |
          translate }}
          <p-sortIcon field="virtual.facilityAddress" />
        </th>
        <th
          pResizableColumn
          pSortableColumn="virtual.ticketStatusType"
          class="white-space-nowrap">
          {{ 'ticketComponent.table.columns.ticketStatusType.title' | translate
          }}
          <p-sortIcon field="virtual.ticketStatusType" />
        </th>
        <th
          pResizableColumn
          pSortableColumn="virtual.editor"
          class="white-space-nowrap">
          {{ 'ticketComponent.table.columns.editor.title' | translate }}
          <p-sortIcon field="editor" />
        </th>
        <!-- <th pSortableColumn="appointment" class="white-space-nowrap">
          {{ 'ticketComponent.table.columns.appointment.title' | translate }}
          <p-sortIcon field="appointment" />
        </th> -->

        <th pResizableColumn pSortableColumn="virtual.createdBy">
          {{ 'ticketComponent.table.columns.createdBy.title' | translate }}
          <p-sortIcon field="createdBy" />
        </th>
        <th
          pResizableColumn
          pSortableColumn="createdAt"
          class="white-space-nowrap">
          {{ 'ticketComponent.table.columns.createdAt.title' | translate }}
          <p-sortIcon field="createdAt" />
        </th>
        <th
          pResizableColumn
          pSortableColumn="virtual.isReoccurringTicket"
          class="white-space-nowrap">
          {{ 'ticketComponent.table.columns.isReoccurringTicket.title' |
          translate }}
          <p-sortIcon field="virtual.isReoccurringTicket" />
        </th>
        <th
          pResizableColumn
          pSortableColumn="executionDate"
          class="white-space-nowrap">
          {{ 'ticketComponent.table.columns.executionDate.title' | translate }}
          <p-sortIcon field="executionDate" />
        </th>
        <th alignFrozen="right" pResizableColumn pFrozenColumn>
          {{ 'table.actionsTitle' | translate}}
        </th>
      </tr>
      <!-- Filters -->
      <tr>
        <th>
          <p-columnFilter
            field="ticketCategoryType"
            matchMode="in"
            [showMenu]="false">
            <ng-template
              pTemplate="filter"
              let-value
              let-filter="filterCallback">
              <p-multiSelect
                appendTo="body"
                [ngModel]="value"
                [optionLabel]="'label'"
                [filter]="false"
                [options]="categoryChips"
                (onChange)="filter($event.value)">
                <ng-template let-option pTemplate="item">
                  <div class="inline-block vertical-align-middle">
                    <app-ticket-chip [label]="option"></app-ticket-chip>
                  </div>
                </ng-template>
                <ng-template let-options pTemplate="selectedItems">
                  <div
                    class="inline-flex align-items-center gap-2 px-1"
                    *ngFor="let option of options; index as i">
                    <div>
                      <app-ticket-chip [label]="option"></app-ticket-chip>
                    </div>
                  </div>
                  <div *ngIf="!options || options.length === 0">&nbsp;</div>
                </ng-template>
              </p-multiSelect>
            </ng-template>
          </p-columnFilter>
        </th>
        <th>
          <p-columnFilter
            field="virtual.virtualSearch.appointmentsAndTechniciansSearch"
            matchMode="contains"
            [showMenu]="false">
            <ng-template
              pTemplate="filter"
              let-value
              let-filter="filterCallback">
              <input
                type="text"
                pInputText
                [ngModel]="value"
                (ngModelChange)="filter($event)"
                class="p-inputtext" />
            </ng-template>
          </p-columnFilter>
        </th>
        <th>
          <p-columnFilter
            field="ticketNumber"
            matchMode="contains"
            [showMenu]="false">
            <ng-template
              pTemplate="filter"
              let-value
              let-filter="filterCallback">
              <input
                type="text"
                pInputText
                [ngModel]="value"
                (ngModelChange)="filter($event)"
                class="p-inputtext" />
            </ng-template>
          </p-columnFilter>
        </th>
        <th>
          <p-columnFilter
            field="subject"
            matchMode="contains"
            [showMenu]="false">
            <ng-template
              pTemplate="filter"
              let-value
              let-filter="filterCallback">
              <input
                type="text"
                pInputText
                [ngModel]="value"
                (ngModelChange)="filter($event)"
                class="p-inputtext" />
            </ng-template>
          </p-columnFilter>
        </th>
        <th>
          <p-columnFilter
            field="virtual.virtualSearch.heatingEngineerSearch"
            matchMode="contains"
            [showMenu]="false">
            <ng-template
              pTemplate="filter"
              let-value
              let-filter="filterCallback">
              <input
                type="text"
                pInputText
                [ngModel]="value"
                (ngModelChange)="filter($event)"
                class="p-inputtext" />
            </ng-template>
          </p-columnFilter>
        </th>
        <th *ngIf="showCustomerColumn">
          <p-columnFilter
            field="virtual.customer"
            matchMode="contains"
            [showMenu]="false">
            <ng-template
              pTemplate="filter"
              let-value
              let-filter="filterCallback">
              <input
                type="text"
                pInputText
                [ngModel]="value"
                (ngModelChange)="filter($event)"
                class="p-inputtext" />
            </ng-template>
          </p-columnFilter>
        </th>
        <th *ngIf="showCustomerColumn">
          <p-columnFilter
            field="virtual.facilityAddress"
            matchMode="contains"
            [showMenu]="false">
            <ng-template
              pTemplate="filter"
              let-value
              let-filter="filterCallback">
              <input
                type="text"
                pInputText
                [ngModel]="value"
                (ngModelChange)="filter($event)"
                class="p-inputtext" />
            </ng-template>
          </p-columnFilter>
        </th>

        <th>
          <p-columnFilter
            field="ticketStatusType"
            matchMode="in"
            [showMenu]="false">
            <ng-template
              pTemplate="filter"
              let-value
              let-filter="filterCallback">
              <p-multiSelect
                appendTo="body"
                [ngModel]="value"
                [filter]="false"
                [options]="statusChips"
                (onChange)="filter($event.value)">
                <ng-template let-option pTemplate="item">
                  <div class="inline-block vertical-align-middle">
                    <app-ticket-chip [label]="option"></app-ticket-chip>
                  </div>
                </ng-template>
                <ng-template let-options pTemplate="selectedItems">
                  <div
                    class="inline-flex align-items-center gap-2 px-1"
                    *ngFor="let option of options; index as i">
                    <div>
                      <app-ticket-chip [label]="option"></app-ticket-chip>
                    </div>
                  </div>
                  <div *ngIf="!options || options.length === 0">&nbsp;</div>
                </ng-template>
              </p-multiSelect>
            </ng-template>
          </p-columnFilter>
        </th>
        <th>
          <p-columnFilter
            field="virtual.editor"
            matchMode="contains"
            [showMenu]="false">
            <ng-template
              pTemplate="filter"
              let-value
              let-filter="filterCallback">
              <input
                pInputText
                type="text"
                [ngModel]="value"
                (ngModelChange)="filter($event)"
                class="p-column-filter" />
            </ng-template>
          </p-columnFilter>
        </th>

        <th>
          <p-columnFilter
            field="virtual.createdBy"
            matchMode="contains"
            [showMenu]="false">
            <ng-template
              pTemplate="filter"
              let-value
              let-filter="filterCallback">
              <input
                pInputText
                type="text"
                [ngModel]="value"
                (ngModelChange)="filter($event)"
                class="p-column-filter" />
            </ng-template>
          </p-columnFilter>
        </th>
        <th>
          <p-columnFilter field="createdAt" [showMenu]="false" matchMode="date">
            <ng-template
              pTemplate="filter"
              let-value
              let-filter="filterCallback">
              <p-calendar
                [showTime]="false"
                [dateFormat]="'dd.mm.yy'"
                [ngModel]="value"
                (ngModelChange)="filter($event)"
                appendTo="body"
                selectionMode="range"
                [readonlyInput]="true"
                [style]="{ width: '12rem' }" />
            </ng-template>
          </p-columnFilter>
        </th>
        <th>
          <p-columnFilter
            field="virtual.isReoccurringTicket"
            matchMode="contains"
            [showMenu]="false">
            <ng-template
              pTemplate="filter"
              let-value
              let-filter="filterCallback">
              <input
                type="text"
                pInputText
                [ngModel]="value"
                (ngModelChange)="filter($event)"
                class="p-inputtext" />
            </ng-template>
          </p-columnFilter>
        </th>
        <th>
          <p-columnFilter
            field="executionDate"
            [showMenu]="false"
            matchMode="date">
            <ng-template
              pTemplate="filter"
              let-value
              let-filter="filterCallback">
              <p-calendar
                [showTime]="false"
                [dateFormat]="'dd.mm.yy'"
                [ngModel]="value"
                (ngModelChange)="filter($event)"
                appendTo="body"
                selectionMode="range"
                [readonlyInput]="true"
                [style]="{ width: '12rem' }" />
            </ng-template>
          </p-columnFilter>
        </th>
        <th alignFrozen="right" pFrozenColumn>
          <div class="flex gap-2">
            <button
              pButton
              pRipple
              [pTooltip]="'general.tooltips.manageFilters' | translate"
              tooltipPosition="left"
              (click)="filterManagement.toggle($event)"
              [style]="'border-radius: 50%; width: 2rem !important;height: 2rem!important;'"
              icon="pi pi-bars"></button>
            <button
              pButton
              pRipple
              [pTooltip]="'general.tooltips.saveFilters' | translate"
              tooltipPosition="left"
              [style]="'border-radius: 50%; width: 2rem !important;height: 2rem!important;'"
              (click)="filterCreate.toggle($event)"
              icon="pi pi-save"></button>
            <button
              pButton
              pRipple
              [pTooltip]="'general.tooltips.removeFilter' | translate"
              tooltipPosition="left"
              [style]="'border-radius: 50%; width: 2rem !important;height: 2rem!important;'"
              (click)="clearFilters()"
              icon="pi pi-filter-slash"></button>
          </div>
          <p-overlayPanel
            #filterManagement
            (onShow)="displayFilterOverlay = $event">
            <app-filter
              #appFilter
              table="ticket"
              (filterApplied)="filterApplied($event)"></app-filter>
          </p-overlayPanel>
          <p-overlayPanel #filterCreate>
            <app-filter-create
              (saveFilterClicked)="saveFilterClicked($event);filterCreate.toggle($event)"></app-filter-create>
          </p-overlayPanel>
        </th>
      </tr>
    </ng-template>
    <!-- Body -->
    <ng-template pTemplate="body" let-ticket>
      <tr>
        <td>
          <div>
            <div>
              <app-ticket-chip
                [label]="ticket.ticketCategoryType"></app-ticket-chip>
            </div>
            <div>
              <span
                *ngIf="ticket.ticketCategoryType === 'Emergency' && ticket.ticketStatusType !== 'Cancelled' && ticket.ticketStatusType !== 'Completed'"
                class="emergency-timestamp"
                >{{getTimestampDisplay(ticket.createdAt)}}</span
              >
            </div>
          </div>
        </td>
        <td class="white-space-nowrap">
          <div
            class="cut-text"
            [pTooltip]="ticket.virtual.virtualData.appointmentsAndTechnicians">
            {{ticket.virtual.virtualData.appointmentsAndTechnicians}}
          </div>
        </td>
        <td class="white-space-nowrap">
          <div class="cursor-pointer" (click)="editTicket(ticket)">
            {{ticket.ticketNumber}}
          </div>
        </td>
        <td>
          <div class="flex gap-3 align-items-center">
            <div
              class="custom-subject-cut-text cursor-pointer"
              [pTooltip]="ticket.subject"
              (click)="editTicket(ticket)">
              {{ticket.subject}}
            </div>
          </div>
        </td>
        <td>
          <span
            *ngFor="let heatingEngineer of ticket.virtual.virtualData.heatingEngineers; last as isLast"
            [pTooltip]="heatingEngineer.deviceSerialNumbers">
            <!-- Do not format this code or else it will insert a space and that looks bad -->
            <span>{{ heatingEngineer.name }}</span>
            <span *ngIf="!isLast">;&nbsp;</span>
          </span>
        </td>
        <td *ngIf="showCustomerColumn">
          <app-customer-status-badge
            [customer]="ticket.customer"></app-customer-status-badge>
        </td>
        <td *ngIf="showCustomerColumn">
          <div class="flex flex-column" *ngIf="ticket.customer">
            <span>
              {{ "customerComponent.facilityAddressType." +
              ticket.customer.facilityAddressType | translate }}
            </span>
            <span
              [pTooltip]="formatAddress(ticket.customer.facilityAddress)"
              class="cut-text"
              [ngStyle]="{
                'max-width': '500px',
                'min-width': '300px',
             }">
              {{ formatAddress(ticket.customer.facilityAddress) }}
            </span>
          </div>
        </td>

        <td>
          <div class="flex gap-3 align-items-center">
            <app-ticket-chip
              [label]="ticket.ticketStatusType"></app-ticket-chip>
          </div>
        </td>
        <td>
          <div
            class="flex gap-3 align-items-center white-space-nowrap"
            *ngIf="ticket.editor">
            <app-user-avatar [user]="ticket.editor" size="large" />
            <span>{{ticket.editor.firstname}} {{ticket.editor.lastname}}</span>
          </div>
        </td>

        <!-- <td>
          <div class="flex gap-3 align-items-center"></div>
        </td> -->
        <td>
          <div class="flex gap-3 align-items-center white-space-nowrap">
            <app-user-avatar [user]="ticket.createdBy" size="large" />
            <span
              >{{ticket.createdBy.firstname}}
              {{ticket.createdBy.lastname}}</span
            >
          </div>
        </td>
        <td>
          <div class="flex gap-3 align-items-center">
            {{ticket.createdAt | date: 'dd.MM.yyyy'}}
          </div>
        </td>
        <td>{{ticket.virtual.isReoccurringTicket}}</td>
        <td>
          <div class="flex gap-3 align-items-center">
            {{ticket.executionDate | date: 'dd.MM.yyyy'}}
          </div>
        </td>

        <td alignFrozen="right" pFrozenColumn>
          <div class="flex gap-3 align-items-center">
            <app-table-action
              *ngIf="showUpdateButton"
              (actionClicked)="editTicket(ticket)"
              [icon]="'pi pi-pencil'"
              [severity]="'success'"
              [pTooltip]="'ticketComponent.actions.tooltips.edit' | translate"></app-table-action>

            <app-table-action
              *ngIf="!showUpdateButton"
              (actionClicked)="editTicket(ticket)"
              [icon]="'pi pi-eye'"
              [severity]="'info'"
              tooltipPosition="left"
              [pTooltip]="'ticketComponent.actions.tooltips.view' | translate"></app-table-action>

            <div *ngIf="showPdfActions(ticket)" class="action-divider"></div>
            <app-table-action
              *ngIf="showPdfActions(ticket)"
              (actionClicked)="viewPdfs(ticket)"
              [icon]="'pi pi-eye'"
              [severity]="'success'"
              [pTooltip]="'formComponent.actions.tooltips.view' | translate"></app-table-action>

            <app-table-action
              *ngIf="showPdfActions(ticket)"
              (actionClicked)="download(ticket)"
              [icon]="'pi pi-file-pdf'"
              [severity]="'danger'"
              [pTooltip]="'formComponent.actions.tooltips.pdf' | translate"></app-table-action>
          </div>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>

<p-dialog
  [(visible)]="isFileModalVisible"
  [draggable]="false"
  [modal]="true"
  [dismissableMask]="true"
  [closable]="true"
  [style]="{ width: '92%' }"
  (onHide)="hidePdfViewer()">
  <ng-template pTemplate="headless">
    <div>
      <div *ngIf="showViewerArrows() && formIndex > 0" class="arrow left">
        <i
          class="pi pi-arrow-circle-left"
          style="font-size: 3.5rem"
          (click)="viewPreviousPdf()"></i>
      </div>

      <ngx-doc-viewer
        *ngIf="fileToView && !loading"
        [url]="fileToView"
        viewer="pdf"
        style="height: 80vh" />
      <div
        *ngIf="showViewerArrows() && formIndex < formsToView.length -1"
        class="arrow right">
        <i
          class="pi pi-arrow-circle-right"
          style="font-size: 3.5rem"
          (click)="viewNextPdf()"></i>
      </div>
    </div>

    <p-progressSpinner *ngIf="!fileToView || loading"></p-progressSpinner>
  </ng-template>
</p-dialog>
