/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { FullCalendarComponent } from '@fullcalendar/angular';
import {
  CalendarOptions,
  DateInput,
  DateRangeInput,
  DateSelectArg,
  EventClickArg
} from '@fullcalendar/core';
import deLocale from '@fullcalendar/core/locales/de';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem } from 'primeng/api';
import { ContextMenu } from 'primeng/contextmenu';
import { catchError, first, Subscription, throwError } from 'rxjs';
import { DataModificationMethod } from 'src/app/enums/DataModificationMethod';
import { Appointment } from 'src/app/models/Appointment';
import { Address } from 'src/app/models/customer/Address';
import { Ticket } from 'src/app/models/Ticket';
import { User } from 'src/app/models/User';
import { AppointmentService } from 'src/app/services/api/appointment.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { MessageCenterService } from 'src/app/services/message-center.service';
import { Severity } from 'src/app/types/misc/Severity';
import { AppAction } from 'src/config/authorization.config';
import { environment } from 'src/environments/environment';
import tippy from 'tippy.js';
import { Clipboard } from '@angular/cdk/clipboard';
import { DeleteAppointmentModalComponent } from './delete-appointment-modal/delete-appointment-modal.component';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnInit, OnChanges, AfterViewInit {
  @ViewChild('calendar') calendar?: FullCalendarComponent;

  @ViewChild('menu') menu?: ContextMenu;

  @ViewChild(DeleteAppointmentModalComponent)
  deleteAppointmentModal!: DeleteAppointmentModalComponent;

  @Input() tickets: Ticket[] = [];

  @Input() users: User[] = [];

  @Input() selectedUsers: User[] = [];

  @Input() calendarViewChange = false;

  @Input() selectedTicket?: Ticket;

  @Output() selectedTicketChange = new EventEmitter<Ticket>();

  @Output() resetTicket = new EventEmitter<void>();

  @Output() selectedUsersChange = new EventEmitter<User[]>();

  @Output() removeTicketFromList = new EventEmitter<number>();

  @Output() appointmentDeleted = new EventEmitter<void>();

  @Output() reInitTickets = new EventEmitter<void>();

  appointments: Appointment[] = [];

  filteredAppointments: Appointment[] = [];

  today = '';

  // calender internal Options
  calendarOptions: any = {
    initialView: 'dayGridWeek'
  };

  // calender internal event
  clickedEvent!: any;

  showDialog = false;

  dateClicked = false;

  edit = false;

  view = '';

  appointment!: Appointment;

  year!: number;

  month!: number;

  startDate!: Date;

  endDate!: Date;

  menuItems: MenuItem[] = [];

  showDeleteAppointmentModal = false;

  subscriptions = new Subscription();

  tempNotes = '';

  constructor(
    private readonly appointmentService: AppointmentService,
    private readonly messageCenterService: MessageCenterService,
    private readonly translate: TranslateService,
    private readonly authService: AuthService,
    private clipboard: Clipboard
  ) {}

  ngOnInit(): void {
    this.appointment = new Appointment();

    const currentDate = new Date();
    this.year = currentDate.getFullYear();
    this.month = currentDate.getMonth() + 1;
    const monthStr = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');

    this.today = `${this.year}-${monthStr}-${day}`;
    this.calendarOptions = this.getInitialCalendarOptions();
  }

  ngAfterViewInit(): void {
    this.removeIconClasses('.fc-icon.pi-calendar');
    this.removeIconClasses('.fc-icon.pi-calendar-plus');
  }

  /**
   * Handles the event when dates are set. Converts the start and end dates from the event
   * and updates the component's startDate and endDate properties. If both dates are set,
   * it triggers the fetching of appointments.
   *
   * @param {Event} dateInfo - The event containing the date range information.
   */
  onDatesSet(dateInfo: Event): void {
    const dateInfoInput = dateInfo as DateRangeInput;

    if (dateInfoInput.start) {
      const start = this.convertToDate(dateInfoInput.start);
      if (start) {
        this.startDate = start;
      }
    }
    if (dateInfoInput.end) {
      const end = this.convertToDate(dateInfoInput.end);
      if (end) {
        this.endDate = end;
      }
    }

    if (this.startDate && this.endDate) {
      this.getAppointments();
    }
  }

  /**
   * Converts a date input to a Date object. The input can be a Date object, a string, a number,
   * or an array representing the date components.
   *
   * @param {DateInput} dateInput - The input to be converted to a Date object.
   * @returns {Date | null} - The converted Date object, or null if the input is invalid.
   */
  convertToDate(dateInput: DateInput): Date | null {
    if (dateInput instanceof Date) {
      return dateInput;
    } else if (typeof dateInput === 'string' || typeof dateInput === 'number') {
      return new Date(dateInput);
    } else if (Array.isArray(dateInput)) {
      const [year, month, day, hour = 0, minute = 0, second = 0] = dateInput;

      return new Date(year, month - 1, day, hour, minute, second);
    }

    return null;
  }

  /**
   * Removes specific icon classes from the element matching the given selector.
   *
   * @private
   * @param {string} selector - The CSS selector of the element to remove classes from.
   * @returns {void}
   */
  private removeIconClasses(selector: string): void {
    const element = document.querySelector(selector);
    if (element) {
      element.classList.remove('fc-icon');
      element.classList.remove('fc-icon-');
    }
  }

  /**
   * Responds to changes in the input properties of the component.
   *
   * @param {SimpleChanges} changes - The changes in the input properties.
   * @returns {void}
   */
  ngOnChanges(changes: SimpleChanges): void {
    // Check if the 'users' input property has changed
    if (changes['users']) {
      // Update the filtered appointments with the new user data
      this.filteredAppointments = this.filteredAppointments.map(
        (appointment) => {
          // Find the technician corresponding to the appointment
          const technician = this.users.find(
            (user) => user.id === appointment.technicianId
          );
          if (technician) {
            // Update the appointment with the technician's details
            appointment.technician = technician;
            appointment.backgroundColor = technician.color || '#000';
            appointment.borderColor = technician.color || '#000';
            appointment.textColor = this.getTextColorBasedOnBackground(
              technician.color || '#000'
            );
          }

          return appointment;
        }
      );
      // Update the calendar events with the filtered appointments
      this.calendarOptions.events = this.filteredAppointments;
    }

    // Check if the 'calendarViewChange' input property has changed
    if (changes['calendarViewChange']?.currentValue) {
      // Wait for the view to change (animation duration is 250ms, using 500ms for safety)
      setTimeout(() => {
        this.calendar?.getApi().updateSize();
      }, 500);
    }

    // Check if the 'selectedTicket' input property has changed
    if (changes['selectedTicket']?.currentValue) {
      // Update the appointment with the selected ticket details
      this.appointment.ticket = this.selectedTicket;

      if (this.selectedTicket?.subject) {
        this.appointment.title = this.selectedTicket.subject;
      }
      if (this.selectedTicket?.description) {
        this.appointment.description = this.selectedTicket.description;
      }
      // Switch to the 'new' view and show the dialog
      this.view = 'new';
      this.showDialog = true;
    }

    // Check if the 'selectedUsers' input property has changed
    if (changes['selectedUsers']?.currentValue) {
      // If this is not the first change, fetch the appointments
      if (!changes['selectedUsers'].isFirstChange()) {
        this.getAppointments();
      }
    }
  }

  /**
   * Returns the initial configuration options for the calendar.
   * Check https://fullcalendar.io/docs/angular for further information
   *
   * @returns {any} The initial calendar options.
   */
  getInitialCalendarOptions(): any {
    let customButtons: CalendarOptions['customButtons'] | undefined;

    if (this.$can('create')) {
      customButtons = {
        calendarPlusButton: {
          icon: ' pi pi-calendar-plus',
          click: () => {
            this.initNewAppointment();
          }
        }
      };
    }

    return {
      // Custom buttons for the calendar toolbar
      customButtons,
      // Events to be displayed on the calendar
      events: this.filteredAppointments,
      // Plugins used by the calendar
      plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
      // Allow events to be dropped onto the calendar
      droppable: true,
      // Set the locale to German
      locale: deLocale,
      locales: [deLocale],
      // Set the initial date to today
      initialDate: this.today,
      initialView: 'timeGridWeek',
      // Set the height of the calendar
      height: '80vh',
      // Configure the header toolbar
      headerToolbar: {
        left: `prev,next today${
          this.$can('create') ? ' calendarPlusButton' : ''
        }`,
        center: 'title',
        right: 'dayGridMonth,timeGridWeek,timeGridDay'
      },
      // Allow events to be edited and selected
      editable: true,
      selectable: this.$can('create'),
      selectMirror: this.$can('create'),
      dayMaxEvents: true,
      nowIndicator: true,
      weekNumbers: true,
      navLinks: true,
      businessHours: [
        {
          daysOfWeek: [1, 2, 3, 4, 5],
          startTime: '07:30',
          endTime: '18:00'
        }
      ],
      // Update start and end dates when the calendar dates are set
      datesSet: this.onDatesSet.bind(this),
      // Configure tooltips for events
      eventMouseEnter: (info: any) => {
        if (info.event.id) {
          const { ticket, technician } = info.event.extendedProps;
          const customer = ticket?.customer;

          tippy(info.el, {
            content: `
            <div class="tippy-header">
              <div><b>${info.event.title}</b></div>
              <div>${ticket ? ticket.requestReason : ''}</div>
              <div class="mt-2 flex">
                <div class='w-4'><b>Beginn:</b></div>
                <div>${info.event.start?.toLocaleString()}</div>
              </div>
              <div class="mt-2 flex">
                <div class='w-4'><b>Ende:</b></div>
                <div>${info.event.end ? info.event.end?.toLocaleString() : 'Ganztägig'}</div>
              </div>
            </div>
            ${
              technician
                ? `<div class="tippy-body">
                    <div><b>${technician.firstname} ${technician.lastname}</b></div>

                    ${
                      customer
                        ? `
                        <hr>
                        <div><b>${customer.customerNumber}</b></div>
                        <div>${customer.name}</div>
                        <div>${customer.facilityAddress ? this.formatAddress(customer.facilityAddress, 'street') : ''}</div>
                        <div>${customer.facilityAddress ? this.formatAddress(customer.facilityAddress, 'postal') : ''}</div>
                        `
                        : ''
                    }
                  </div>
                  `
                : ''
            }
          `,
            duration: [500, 0],
            allowHTML: true,
            appendTo: document.body,

            onHidden: (instance) => {
              instance.destroy();
            }
          });
        }
      },
      // Handle click on a event (appointment)
      eventClick: (e: EventClickArg) => {
        this.onEventClick(e);
      },
      // Handle date selection
      select: (e: DateSelectArg) => {
        if (!this.$can('create')) {
          return;
        }

        this.onDateSelect(e);
      },
      // Handle event (appointment) drop
      eventDrop: (e: any) => {
        if (!this.$can('create')) {
          return;
        }

        this.onEventClick(e);
        this.handleSave();
      },
      // Handle event (appointment) resize
      eventResize: (e: any) => {
        if (!this.$can('create')) {
          return;
        }

        this.onEventClick(e);
        this.handleSave();
      },
      // Handle event (ticket) receive
      eventReceive: (eventReceiveEvent: any) => {
        if (!this.$can('create')) {
          return;
        }

        this.onEventReceive(eventReceiveEvent);
      },
      eventDidMount: this.addContextMenuListener.bind(this),
      // Format for displaying event times
      eventTimeFormat: {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false
      }
    };
  }

  /**
   * Initializes a new appointment with default values and sets the view to 'new'.
   *
   * @returns {void}
   */
  initNewAppointment(): void {
    this.appointment = {
      id: 0,
      originalId: 0,
      allDay: false,
      reserved: false,
      title: '',
      start: new Date(),
      end: new Date(),
      description: '',
      backgroundColor: '',
      borderColor: '',
      textColor: ''
    };
    this.view = 'new';
    this.showDialog = true;
  }

  formatAddress(address: Address, returnType?: 'street' | 'postal'): string {
    if (returnType === 'street') {
      return `${address.street}`;
    }

    if (returnType === 'postal') {
      return `${address.postalCode} ${address.city}`;
    }

    if (!returnType) {
      return `${address.street}, ${address.postalCode} ${address.city}`;
    }

    // Default fallback in case of unexpected input
    return '';
  }

  /**
   * Fetches appointments for the selected year, month, and users. Updates the appointments,
   * filtered appointments, and calendar options with the fetched data.
   *
   * @returns {void}
   */
  getAppointments(): void {
    // Get the IDs of the selected users, defaulting to [0] if no users are selected
    const userIds = this.selectedUsers
      ? this.selectedUsers.map((user) => user.id ?? 0)
      : [0];

    if (this.startDate && this.endDate) {
      // Fetch appointments from the appointment service
      this.subscriptions.add(
        this.appointmentService
          .findAllByDateRangeAndUser(this.startDate, this.endDate, userIds)
          .subscribe((appointments) => {
            this.appointments = appointments;
            this.addAppointmentsForOtherTechnicians();
            this.filterAppointments();
          })
      );
    }
  }

  /**
   * Adds appointments for other technicians to the calendar.
   * This method creates new appointments for each technician in the `otherTechnicians` array
   * and adjusts the appointment colors based on the technician's color.
   *
   * @returns {void}
   */
  addAppointmentsForOtherTechnicians(): void {
    const additionalAppointments: Appointment[] = [];

    this.appointments.forEach((appointment) => {
      if (appointment.otherTechnicians) {
        appointment.otherTechnicians.forEach((technician) => {
          // Check if the technician is in the selected users list
          if (this.selectedUsers.some((user) => user.id === technician.id)) {
            // Create a new appointment for the technician
            const newAppointment = { ...appointment };
            newAppointment.backgroundColor = technician.color || '#000';
            newAppointment.borderColor = technician.color || '#000';
            newAppointment.textColor = this.getTextColorBasedOnBackground(
              technician.color || '#000'
            );

            additionalAppointments.push(newAppointment);
          }
        });
      }
    });

    const filteredAppointments = this.appointments.filter(
      (appointment) =>
        appointment.technician &&
        this.selectedUsers.some(
          (user) =>
            appointment.technician && user.id === appointment.technician.id
        )
    );

    // Add the new appointments to the existing appointments
    this.appointments = [...filteredAppointments, ...additionalAppointments];
  }

  /**
   * Handles the event receive action. Sets the received event, converts it to a plain object,
   * and updates the view to 'display' mode. Also shows the dialog and sets the appointment details.
   *
   * @param {EventClickArg} e - The event object containing the clicked event details.
   * @returns {void}
   */
  onEventReceive(e: EventClickArg): void {
    this.clickedEvent = e.event;
    const plainEvent = e.event.toPlainObject({
      collapseExtendedProps: true,
      collapseColor: true
    });
    this.view = 'new';
    this.showDialog = true;

    this.appointment = { ...plainEvent, ...this.clickedEvent };
    this.appointment.start = this.clickedEvent.start;
    this.appointment.end = this.clickedEvent.end
      ? this.clickedEvent.end
      : this.clickedEvent.start + 1;

    if (this.selectedUsers && this.selectedUsers.length === 1) {
      // eslint-disable-next-line prefer-destructuring
      this.appointment.technician = this.selectedUsers[0];
    }
  }

  /**
   * Filters the appointments and updates the calendar options.
   * This method assigns a unique random ID to each appointment to ensure uniqueness.
   *
   * @returns {void}
   */
  filterAppointments(): void {
    // Update the appointments and filtered appointments lists

    this.filteredAppointments = this.appointments.map((appointment) => {
      let randomNumber;

      // Set to keep track of already used numbers
      const usedNumbers = new Set(
        this.filteredAppointments?.map((appt) => appt.id) || []
      );

      // Ensure the random number is unique
      while (usedNumbers.has(randomNumber) || !randomNumber) {
        randomNumber = Math.random();
      }

      // Add the new random number to the set
      usedNumbers.add(randomNumber);

      return {
        ...appointment,
        originalId: appointment.id,
        id: randomNumber
      };
    });

    // Update the calendar options with the fetched appointments
    this.calendarOptions = {
      ...this.calendarOptions,
      ...{ events: this.filteredAppointments }
    };
  }

  /**
   * Handles the event click action. Sets the clicked event, converts it to a plain object,
   * and updates the view to 'display' mode. Also shows the dialog and sets the appointment details.
   *
   * @param {EventClickArg} e - The event object containing the clicked event details.
   * @returns {void}
   */
  onEventClick(e: EventClickArg): void {
    this.clickedEvent = e.event;
    const plainEvent = e.event.toPlainObject({
      collapseExtendedProps: true,
      collapseColor: true
    });
    this.view = 'display';
    this.showDialog = true;

    this.appointment = { ...plainEvent, ...this.clickedEvent };
    this.appointment.start = this.clickedEvent.start;
    this.appointment.end = this.clickedEvent.end
      ? this.clickedEvent.end
      : this.clickedEvent.start;
  }

  addContextMenuListener(info: any): void {
    info.el.addEventListener('contextmenu', (event: MouseEvent) => {
      const { ticket } = info.event.extendedProps;
      const customer = ticket?.customer;

      event.preventDefault(); // Prevent browser context menu from opening
      this.clickedEvent = info.event;

      // Context menu items
      this.menuItems = [];

      // add address-specific context menu items
      if (customer && customer.facilityAddress) {
        this.menuItems.push({
          label: this.translate.instant(
            'calendarComponent.rightClickMenu.maps'
          ),
          icon: 'pi pi-map',
          command: () => this.openGoogleMaps(true)
        });
        this.menuItems.push({
          label: this.translate.instant(
            'calendarComponent.rightClickMenu.copy'
          ),
          icon: 'pi pi-copy',
          command: () => this.openGoogleMaps(false)
        });
      }
      // add delete context menu item
      this.menuItems.push({
        label: this.translate.instant(
          'calendarComponent.rightClickMenu.delete'
        ),
        icon: 'pi pi-map',
        command: () => this.showDeleteModal()
      });
      // show the context menu
      this.menu?.show(event);
    });
  }

  openGoogleMaps(openTab: boolean): void {
    const { ticket } = this.clickedEvent.extendedProps;
    const customer = ticket?.customer;
    const address = this.formatAddress(customer?.facilityAddress);
    const encodedAddress = encodeURIComponent(address);
    const googleMapsUrl = `https://www.google.com/maps?q=${encodedAddress}`;
    if (openTab) {
      window.open(googleMapsUrl, '_blank');
    } else {
      this.clipboard.copy(address);

      this.messageCenterService.showToast(
        this.translate.instant(
          'calendarComponent.actions.toasts.copyLink.success.summary'
        ),
        this.translate.instant(
          'calendarComponent.actions.toasts.copyLink.success.detail'
        ),
        'success'
      );
    }
  }

  /**
   * Handles the date selection action. Sets the view to 'new' mode, shows the dialog,
   * and initializes the appointment details with the selected date range.
   *
   * @param {DateSelectArg} e - The event object containing the selected date range details.
   * @returns {void}
   */
  onDateSelect(e: DateSelectArg): void {
    this.view = 'new';
    this.showDialog = true;
    this.appointment = {
      ...e,
      id: 0,
      originalId: 0,
      start: e.start,
      end: e.end,
      title: '',
      description: '',
      backgroundColor: '',
      notes: '',
      borderColor: '',
      textColor: '',
      forms: [],
      technician:
        this.selectedUsers && this.selectedUsers.length === 1
          ? this.selectedUsers[0]
          : undefined
    };
  }

  /**
   * Handles the save action for an appointment. Validates the appointment, updates its properties,
   * and either creates a new appointment or updates an existing one.
   *
   * @returns {void}
   */
  handleSave(): void {
    // Validate the appointment
    if (!this.validate()) {
      return;
    }

    // Hide the dialog
    this.showDialog = false;

    // Set background and text colors
    const backgroundColor = this.appointment.technician?.color || '#FFFFFF';
    const textColor = this.getTextColorBasedOnBackground(backgroundColor);

    // Update appointment properties
    this.appointment = {
      ...this.appointment,
      backgroundColor,
      borderColor: backgroundColor,
      textColor
    };
    if (!this.appointment.forms && this.appointment.ticket?.forms) {
      this.appointment.forms = this.appointment.ticket.forms;
    }
    this.appointment.forms?.forEach((form) => {
      if (!form.deadline) {
        const appointmentEndDate = new Date(this.appointment.end);
        appointmentEndDate.setDate(
          appointmentEndDate.getDate() + environment.deadlineOffset.days
        );
        form.deadline = appointmentEndDate;
      }
      if (!form.technician) {
        form.technician = this.appointment.technician;
      }
    });

    this.appointment.ticketId = this.appointment.ticket?.id;
    this.appointment.technicianId = this.appointment.technician?.id;

    this.saveAppointment();
  }

  saveAppointment(): void {
    // Check if the appointment already has an ID (existing appointment)
    if (this.appointment.originalId) {
      // Update the existing appointment
      this.subscriptions.add(
        this.appointmentService
          .edit(this.appointment.originalId, this.buildAppointment())
          .subscribe({
            next: (updatedAppointment) => {
              if (updatedAppointment) {
                this.getAppointments();
                // Update the calendar options
                this.updateCalendarOptions();
                this.reInitTickets.emit();
                this.appointment = new Appointment();
              }
            },
            error: (error) => {
              console.error('Error updating appointment:', error);
            }
          })
      );
    } else {
      // Create a new appointment
      this.appointmentService
        .create(this.getPlainAppointment(this.appointment))
        .subscribe({
          next: (newAppointment) => {
            this.getAppointments();
            // Update the calendar options
            this.updateCalendarOptions();
            // Reset the appointment object
            this.appointment = new Appointment();
            if (newAppointment.ticketId) {
              this.removeTicketFromList.emit(newAppointment.ticketId);
            }
            this.reInitTickets.emit();
          },
          error: (error) => {
            console.error('Error creating appointment:', error);
          }
        });
    }
  }

  /**
   * Converts an Appointment object to a plain object with only the necessary properties.
   * This helps to avoid circular references when serializing the object to JSON.
   *
   * @param {Appointment} appointment - The appointment object to be converted.
   * @returns {Object} A plain object containing the necessary properties of the appointment.
   */
  private getPlainAppointment(appointment: Appointment): any {
    return {
      title: appointment.title,
      description: appointment.description,
      start: appointment.start,
      end: appointment.end,
      technician: appointment.technician,
      allDay: appointment.allDay,
      reserved: appointment.reserved,
      notes: appointment.notes,
      technicianId: appointment.technician?.id,
      forms: appointment.forms,
      otherTechniciansIds: appointment.otherTechnicians?.map(
        (technician) => technician.id
      ),
      backgroundColor: appointment.technician?.color || '#a38f0a',
      ticketId: appointment.ticket?.id,
      borderColor: appointment.technician?.color || '#000',
      textColor: this.getTextColorBasedOnBackground(
        appointment.technician?.color || '#000'
      )
    };
  }

  /**
   * Updates the calendar options with the filtered appointments.
   *
   * @private
   * @returns {void}
   */
  private updateCalendarOptions(): void {
    this.calendarOptions = {
      ...this.calendarOptions,
      events: this.filteredAppointments
    };
  }

  /**
   * Sets the view to 'edit' mode.
   *
   * @returns {void}
   */
  async onEditClick(): Promise<void> {
    const now = new Date();
    const appointmentStart = new Date(this.appointment.start);

    if (appointmentStart <= now) {
      const edit = await new Promise<boolean>((resolve) => {
        this.messageCenterService.confirm(
          this.translate.instant(
            'calendarComponent.appointment.editAppointment.editWarning.title'
          ),
          this.translate.instant(
            'calendarComponent.appointment.editAppointment.editWarning.summary'
          ),
          () => {
            resolve(true);
          },
          () => {
            resolve(false);
          }
        );
      });
      if (edit) {
        this.view = 'edit';
      }
    } else {
      this.view = 'edit';
    }
  }

  /**
   * Updates the notes of the current appointment.
   *
   * @param {string} notes - The new notes for the appointment.
   */
  appointmentNotesChanged(notes: string): void {
    this.tempNotes = notes;
  }

  /**
   * Shows the delete modal and deletes the appointment if confirmed.
   *
   * @returns {Promise<void>} A promise that resolves when the delete operation is complete.
   */
  async showDeleteModal(): Promise<void> {
    const { originalId } = this.clickedEvent.extendedProps;
    this.appointment =
      this.appointments.find((a) => a.id === originalId) || new Appointment();
    const oldNotes = this.appointment.notes || '';
    const save = await this.showDeleteAppointmentModalClicked();
    this.tempNotes = this.appointment.notes || '';
    if (save && originalId) {
      this.delete(originalId);
    } else {
      this.appointment.notes = oldNotes;
      this.appointment = new Appointment();
      this.messageCenterService.showToast(
        this.translate.instant(
          `calendarComponent.actions.toasts.delete.info.summary`
        ),
        this.translate.instant(
          `calendarComponent.actions.toasts.delete.info.detail`
        ),
        'info'
      );
    }
    this.tempNotes = '';
  }

  /**
   * Shows the delete appointment modal and waits for the user's response.
   *
   * @returns {Promise<boolean>} A promise that resolves to true if the user confirms the deletion, false otherwise.
   */
  async showDeleteAppointmentModalClicked(): Promise<boolean> {
    this.showDeleteAppointmentModal = true;
    const save = await new Promise<boolean>((resolve) => {
      this.subscriptions.add(
        this.deleteAppointmentModal.modalClosed.subscribe((closed: boolean) => {
          resolve(closed);
        })
      );
    });

    this.showDeleteAppointmentModal = false;

    return save;
  }

  /**
   * Deletes the clicked event from the filtered appointments and updates the calendar options.
   *
   * @returns {void}
   */
  delete(id: number): void {
    let idToDelete;
    if (this.appointment.originalId) {
      idToDelete = this.appointment.originalId;
    } else if (id) {
      idToDelete = id;
    }
    if (idToDelete && this.tempNotes) {
      this.appointmentService
        .deleteWithNotes(idToDelete, this.tempNotes)
        .pipe(
          catchError((error) => {
            this.showCrudToast(DataModificationMethod.Delete, 'error');

            return throwError(() => error);
          }),
          first() // Automatically unsubscribe after first value
        )
        .subscribe((deletedAppointment) => {
          if (deletedAppointment) {
            this.filteredAppointments = this.filteredAppointments.filter(
              (appointment) =>
                appointment.originalId?.toString() !== idToDelete?.toString()
            );
            this.calendarOptions = {
              ...this.calendarOptions,
              ...{ events: this.filteredAppointments }
            };
            this.appointmentDeleted.emit();
            this.showDialog = false;
            this.appointment = new Appointment();

            this.showCrudToast(DataModificationMethod.Delete, 'success');
          }
        });
    }
  }

  handleSaveDisabled(): boolean {
    return (
      !this.appointment.start ||
      !this.appointment.end ||
      !this.appointment.technician ||
      !this.appointment.title
    );
  }

  /**
   * Validates the appointment by checking if both start and end times are set.
   *
   * @returns {boolean} True if both start and end times are set, otherwise false.
   */
  validate(): boolean {
    const { start, end } = this.appointment;

    return Boolean(start && end);
  }

  /**
   * Resets the calendar view and selected ticket, and emits a reset event.
   *
   * @returns {void}
   */
  reset(): void {
    this.getAppointments();
    this.view = '';
    this.selectedTicket = undefined;
    this.resetTicket.emit();
  }

  /**
   * Determines the appropriate text color (black or white) based on the given background color.
   *
   * @param {string} backgroundColor - The background color in hex format (e.g., '#RRGGBB').
   * @returns {string} The text color in hex format ('#FFFFFF' for white or '#000000' for black).
   */
  getTextColorBasedOnBackground(backgroundColor: string): string {
    // Convert hex color to RGB
    const hex = backgroundColor.replace('#', '');
    const red = parseInt(hex.substring(0, 2), 16);
    const green = parseInt(hex.substring(2, 4), 16);
    const blue = parseInt(hex.substring(4, 6), 16);

    // Calculate relative luminance
    // eslint-disable-next-line no-mixed-operators
    const luminance = 0.2126 * red + 0.7152 * green + 0.0722 * blue;

    // Return white for dark backgrounds and black for light backgrounds
    return luminance < 140 ? '#FFFFFF' : '#000000';
  }

  /**
   * Builds an Appointment object based on the current appointment details.
   *
   * @returns {Appointment} The constructed Appointment object.
   */
  buildAppointment(): Appointment {
    return {
      id: this.appointment.id,
      title: this.appointment.title,
      start: this.appointment.start,
      end: this.appointment.end,
      allDay: this.appointment.allDay,
      reserved: this.appointment.reserved,
      forms: this.appointment.forms,
      description: this.appointment.description,
      notes: this.appointment.notes,
      backgroundColor: this.appointment.technician?.color || '#000',
      borderColor: this.appointment.technician?.color || '#000',
      textColor: this.getTextColorBasedOnBackground(
        this.appointment.technician?.color || '#000'
      ),
      ticketId: this.appointment.ticketId || this.appointment.ticket?.id,
      ticket: this.appointment.ticket,
      technicianId: this.appointment.technicianId,
      otherTechniciansIds: this.appointment.otherTechniciansIds
    };
  }

  public showCrudToast(
    method: DataModificationMethod | 'isActive' | 'isBlocked',
    severity: Severity
  ): void {
    this.messageCenterService.showToast(
      this.translate.instant(
        `calendarComponent.actions.toasts.${method}.${severity}.summary`
      ),
      this.translate.instant(
        `calendarComponent.actions.toasts.${method}.${severity}.detail`
      ),
      severity
    );
  }

  $can(action: AppAction): boolean {
    return this.authService.$can(action, 'Appointment');
  }
}
